import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import OurActivities from "../components/OurActivities"
import Header from "../components/Header"

const SecondPage = () => (
  <>
    <SEO title="Page two" />
    <Header />
    <OurActivities />
  </>
)

export default SecondPage
